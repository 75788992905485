import Alpine from 'alpinejs'
import '../css/main.scss';
import config from '../../../srv/auth_config.json'
import createAuth0Client from '@auth0/auth0-spa-js';

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import 'chartjs-adapter-moment';

// pour la position des cron
// mécanisme : on stock un INT qui traduit en binaire affiche les cron 
// ex. Le nombre binaire 000000000000000001000000 est équivalent à l'entier 64.
// getChoices(64) donnera 7 (7ème position 1) donc 7h
function getChoices(number) {
    let choices = [];
    let position = 1;

    while (number > 0) {
        // Si le bit de poids faible est 1, ajoutez la position à la liste des choix
        if ((number & 1) === 1) {
            choices.push(position);
        }

        // Décalez les bits vers la droite et passez à la position suivante
        number >>= 1;
        position++;
    }

    return choices;
}
// fonction inverse, on donne le tableau en entrée pour donner l'entier correspondants des heures
function getNumber(choices) {
    let number = 0;
    for (let i = 0; i < choices.length; i++) {
        let position = choices[i];
        // Définir le bit à la position spécifiée à 1
        number |= 1 << (position - 1);
    }
    return number;
}

const MY_API_URL = "/api"

document.addEventListener('alpine:init', () => {

  Alpine.data('accueil', () => ({
    auth0:null,
    user:{},
    alertes: null,
    isAuth:false,
    verificationhourchoices:false,
    flashMessage:null,
    formatDate(timestamp){
      let strDate = new Date(timestamp)
      strDate = strDate.getDate() + "/" + (strDate.getMonth() + 1) + "/" + strDate.getFullYear() + " " + strDate.getHours() + ":" + strDate.getMinutes() + ":" + strDate.getSeconds()
      return strDate
    },
    async isAuthenticated(){
      if(this.auth0 === null){ return this.isAuth }
      this.isAuth = await this.auth0.isAuthenticated(); 
      if(this.isAuth){ 
        this.auth0.getUser().then(u => this.user=u)
      }
      return this.isAuth
    },
    title: document.querySelector("head > title").innerText,
    description: document.querySelector("head > meta[name='description']").getAttribute('content'),
    async login(){
      if(this.auth0 === null){ return false }
      await this.auth0.loginWithRedirect({
        redirect_uri: window.location.origin
      });
    },
    async getToken(){
      return await this.auth0.getTokenSilently();
    },
    async deluser(){
      if(!confirm("Etes-vous sûr de vouloir supprimer votre compte ?")){
        return
      }
      if(this.auth0){
        let token = await this.getToken()
        fetch(`/api/deluser`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(r => r.json()).then(d => {
          if(d.msg == "ok"){
            window.location.reload()
          }
        });
      }
    },
    async logout(){
      if(this.auth0 === null){ return false }
      this.alertes = null
      await this.auth0.logout({
        returnTo: window.location.origin
      });
    },
    async getAlertes(){
      if(this.auth0){
        let token = await this.getToken()
        fetch("/api/alertes", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(r => r.json()).then(d => this.setAlertes(d));
      }
    },
    async delAlerte(id){
      if(this.auth0){
        let token = await this.getToken()
        fetch(`/api/delalerte?id=${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(r => r.json()).then(d => this.setAlertes(d));
      }
    },
    async addAlerte(){
      if(this.auth0){
        let token = await this.getToken()
        let codeInsee = null
        if(Alpine.store('villes').commune){
          codeInsee = Alpine.store('villes').commune.codeInsee.toString()
        }
        let tmin = parseInt(document.querySelector("#alerte-tmin").value)
        
        if( !Number.isInteger(tmin) || !codeInsee.toString().match(/[0-9AB]{5}/)){
          this.flashMessage = {
            "type": "warning",
            "content": "Vous devez saisir une ville et sélectionner un seuil de température minimum avant de sauvegarder votre alerte."
          }
          setTimeout(()=>{
            this.flashMessage = null
          },5000)
          return
        }
        
        // on récupère les choix checked et on convertit les choix binaires en int (voir les descriptions des fonctions getNumber getChoices)
        let cron = parseInt(getNumber(Array.from(document.querySelectorAll(".alerte-cron:checked")).map(cron => cron.value)));

        fetch(`/api/addalerte?tmin=${tmin}&codeInsee=${codeInsee}&cron=${cron}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(r => r.json()).then(d => this.setAlertes(d));
      }
    },
    setAlertes(alertes){
        this.alertes = alertes.map(a => {
          a.cronchoices = getChoices(a.cron) 
          return a
        });
    },
    verificationhourchoice(e){
      const min = 1 ;
      const max = 1 ;
      let checked = document.querySelectorAll('.alerte-cron:checked').length;
      if (checked > max) {
        e.preventDefault();
        this.verificationhourchoices = 'Vous ne pouvez sélectionner que ' + max + ' horaires.';
        setTimeout(()=>{
          this.verificationhourchoices = undefined 
        },5000)
        e.target.checked = false;
        return false
      }else if (checked < min){
        this.verificationhourchoices = 'Veuillez sélectionner au moins ' + min + ' horaire.';
        return false
      }else{
        this.verificationhourchoices = undefined ;
        return true
      }
    },
    init() {
      const query = window.location.search;
      if(query.includes("codeInsee")){
        let params = new URLSearchParams(query)
        let codeInsee = params.get("codeInsee")
        fetch(`${MY_API_URL}/getCommuneByCodeInsee?codeInsee=${codeInsee}`)
        .then(res => res.json())
        .then(commune => {
          Alpine.store("villes").setCommune(commune)
        })
      }
      createAuth0Client({
        domain: config.domain,
        client_id: config.clientId,
        audience: config.audience
      }).then(r => {
        this.auth0 = r
        if (query.includes("code=") && query.includes("state=")) {
          this.auth0.handleRedirectCallback().then(r => {
            this.isAuthenticated()
            this.getAlertes()
            window.history.replaceState({}, document.title, "/");
          })
        }
      })
    }
  }))

  Alpine.store('villes', {
    dayOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    data: [],
    isLoaded: true,
    meteoChart:null,
    commune: null,
    isCommune:false,
    meteo: null,
    meteoProchainsGel: [],
    initChart(meteo){
      this.meteoChart = new Chart(document.getElementById("meteoChart").getContext('2d')
        ,
        {
        type: 'line',
        options: {
          plugins: {
            title: {
              text: 'Températures sur les 7 prochains jours',
              display: true
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          scales:{
            x: {
	            type:'time',
              distribution: 'series',
	            time: {
                unit: 'day',
		            displayFormats: {
                  day: 'D MMM'
                }
	            },
              title: {
                display: true,
                text: 'Date'
              }
	          },
            y: {
              title: {
                display: true,
                text: 'Températures'
              }
            }
          }
        },
        data: {
          datasets:[
	          {
              label: '°C Prochaines Heures',
              fill: false,
              borderColor: 'rgb(0, 0, 0)',
              tension:0.1,
              data: meteo.hourly.map(o => {
                return {
                  x: o.dt * 1000,
                  y: o.temp
                }
              })
            },
            {
              label: 'min °C',
              fill: false,
              borderColor: 'rgb(75, 192, 192)',
              tension:0.1,
              data: meteo.daily.filter((o,i) => i>1).map(o => {
                return {
                  x: o.dt * 1000 - (13 * 60 * 60 * 1000),
                  y: o.temp.min
                }
              })
            },
            {
              label: 'max °C',
              fill: false,
              borderColor: 'rgb(244, 6, 6)',
              tension:0.1,
              data: meteo.daily.filter((o,i) => i>1).map(o => {
                return {
                  x: o.dt * 1000 - (13 * 60 * 60 * 1000),
                  y: o.temp.max
                }
              })
            }

          ]
        } 
      })
    },
    async getCommuneByCodeInsee(codeInsee){
      let res = await fetch(`${MY_API_URL}/getCommuneByCodeInsee?codeInsee=${codeInsee}`)
      let commune = await res.json()
      return commune
    },
    async getCommuneNameByCodeInsee(codeInsee){
      let commune = await this.getCommuneByCodeInsee(codeInsee)
      let communeName = commune.codePostal + " " + commune.nom
      return communeName
    },
    async getCommuneMeteoHREFByCodeInsee(codeInsee){
      let commune = await this.getCommuneByCodeInsee(codeInsee)
      let strTmp = commune.nom.replace(' ','-')
      strTmp = strTmp.replace("'",'-')
      let communeMeteoHREF = 'https://meteofrance.com/previsions-meteo-france/' + strTmp + '/' + commune.codePostal
      return communeMeteoHREF 
    },
    getMeteo(coord){
      this.isLoaded = false
      fetch(`${MY_API_URL}/getMeteo?lat=${coord.lat}&lon=${coord.lon}`)
        .then(r => r.json())
        .then(r => {
          // Meteo
          this.meteo = r 

          if(!this.meteoChart){
            this.initChart(r)
          }else{
            this.meteoChart.destroy()
            this.meteoChart = null
            this.initChart(r)
          }

          // Prochains Gel
          // en fait je mets tout
          // this.meteoProchainsGel = r.daily.filter(o => o.temp.min < 1 && o.dt > (Date.now() / 1000)).map(daily => {
          this.meteoProchainsGel = r.daily.filter( (o,i) => i > 0).map(daily => {
            let date = new Date(daily.dt * 1000)
            date = this.dayOfWeek[date.getDay()] + " " + date.getDate()
            let description = `${daily.weather[0].description.charAt(0).toUpperCase()}${daily.weather[0].description.slice(1)}` 
            return {
              date: date,
              description: description,
              icon: daily.weather[0].icon,
              temp: {
                min: daily.temp.min,
                max: Math.round(daily.temp.max)
              }
            }
          })
          this.isLoaded = true 
        })
    },
    getVilles(event) {
      //let keyEvent = event
      this.isCommune = false 
      let searchVille=document.querySelector("#searchVille").value
      if(searchVille == ""){
        this.data = []
        return
      }
      let searchVilleOption = document.querySelector(`#datalistvilles option[value='${searchVille}']`) 
      if(searchVilleOption){
        if(searchVilleOption.dataset.commune !== undefined){
          this.setCommune(JSON.parse(searchVilleOption.dataset.commune))
        }
      }else{
        fetch(`${MY_API_URL}/getVilles?searchValue=${searchVille}`)
          .then(r => r.json())
          .then(r => {
            this.data = r.sort((a,b) => {
              return parseInt(a.codePostal) - parseInt(b.codePostal) 
            })
          })
      }
    },
    async setCommuneByCodeInsee(codeInsee){
      let commune = await this.getCommuneByCodeInsee(codeInsee)
      this.setCommune(commune)
    },
    setCommune(Commune){
      this.commune = Commune
      this.isCommune = true
      let strTmp = this.commune.nom.replace(' ','-')
      strTmp = strTmp.replace("'",'-')
      this.commune["meteofrhref"] = 'https://meteofrance.com/previsions-meteo-france/' + strTmp + '/' + this.commune.codePostal
      if( this.commune.lat !== undefined && this.commune.lon !== undefined){
        this.getMeteo({
          lat: this.commune.lat,
          lon: this.commune.lon 
        })
      }
      if (history.pushState) {
          window.history.pushState(null,'',`/?codeInsee=${this.commune.codeInsee}`);
      }
    }
  })

})
Alpine.start()
